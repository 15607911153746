import { useEffect, useMemo, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import {
  AvailablePresets,
  DtoFieldType,
  GetFieldsDataResponse,
  WeightByFieldMap,
} from "../types";
import { GET_FIELDS_DATA } from "../graphql";
import {
  getAvailablePresets,
  getFieldsMap,
  getWeightByFieldMap,
  processMatrix,
} from "../utils";
import { selectedHorizonVar, selectedPresetVar } from "../../../apollo/state";
import { HORIZON_MODE } from "../../../constants";
import { sortBy } from "lodash-es";
import { EMPTY_ARR, EMPTY_MATRIX } from "./constants";
import { getCustomizationParams } from "../../../utils";
import { CustomizationVariables } from "../../../@types";

export function useData() {
  const customizationParams = getCustomizationParams();
  const [dataInitialized, setDataInitialized] = useState<boolean>(false);
  const [availablePresets, setAvailablePresets] = useState<AvailablePresets>(
    {} as AvailablePresets
  );
  const [weightByFieldMap, setWeightByFieldMap] = useState<WeightByFieldMap>(
    {}
  );

  const getFieldsQuery = useQuery<
    GetFieldsDataResponse,
    CustomizationVariables
  >(GET_FIELDS_DATA, { variables: customizationParams });
  const fields = getFieldsQuery.data?.fields || EMPTY_ARR;
  const fieldTypes = getFieldsQuery.data?.fieldTypes || EMPTY_ARR;
  const weights = getFieldsQuery.data?.weights || EMPTY_ARR;
  const shortMatrix = getFieldsQuery.data?.shortMatrix || EMPTY_MATRIX;
  const longMatrix = getFieldsQuery.data?.longMatrix || EMPTY_MATRIX;
  const matrixLabels = getFieldsQuery.data?.matrixLabels || EMPTY_MATRIX;
  const queryLoading = getFieldsQuery.loading;

  const selectedHorizon = useReactiveVar(selectedHorizonVar);

  const sortedFields = useMemo(() => {
    return sortBy(fields, "position");
  }, [fields]);

  const fieldTypesMap = useMemo(() => {
    return getFieldsMap<DtoFieldType>(fieldTypes);
  }, [fieldTypes]);

  useEffect(() => {
    if (sortedFields.length && weights.length && !queryLoading) {
      const availablePresets = getAvailablePresets(weights);
      setAvailablePresets(availablePresets);
      selectedPresetVar(availablePresets.standard.key);

      setWeightByFieldMap(getWeightByFieldMap(weights));
      setDataInitialized(true);
    }
  }, [sortedFields, weights, queryLoading]);

  const matrix = useMemo(() => {
    if (dataInitialized && !queryLoading) {
      return processMatrix<number>(
        selectedHorizon === HORIZON_MODE.short ? shortMatrix : longMatrix
      );
    }

    return EMPTY_MATRIX;
  }, [dataInitialized, selectedHorizon, shortMatrix, longMatrix, queryLoading]);

  const connectionLabels = useMemo(() => {
    if (dataInitialized && !queryLoading) {
      return processMatrix<string>(matrixLabels);
    }

    return EMPTY_MATRIX;
  }, [dataInitialized, matrixLabels, queryLoading]);

  return {
    dataInitialized: dataInitialized && !queryLoading,
    fields: sortedFields,
    fieldTypesMap,
    fieldTypes,
    weights,
    availablePresets,
    weightByFieldMap,
    matrix,
    connectionLabels,
  };
}
