export const FIELD_ID_PARAM = ":fieldId";
export const INTRO_STEP = ":introId";

export const STEP_URLS = {
  start: "/start",
  intro: `/intro/${INTRO_STEP}`,
  weighting: "/config",
  pickFields: "/select",
  pickKeysForField: `/analysis/${FIELD_ID_PARAM}`,
};
