import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import RadarWizard from "./RadarWizard";
import IntroWizard from "./IntroWizard";
import { STEP_URLS } from "../constants";
import { NavigateWithState } from "../components/NavigateWithState";
import { groupNameVar, groupExaminationsVar } from "../apollo/state";
import { useEffect, useState } from "react";
import { getCustomizationParams } from "../utils";
import { fetchGroupExaminations } from "../utils/group-examinations";
import { GlobalLoader } from "../components/Loader";

const urlParams = getCustomizationParams();

function LayoutRouter() {
  const [isLoading, setIsLoading] = useState(false);
  const { clientId, groupName } = urlParams;

  useEffect(() => {
    const fetchData = async (clientId: string, groupName: string) => {
      setIsLoading(true);
      const data = await fetchGroupExaminations(clientId, groupName);
      groupExaminationsVar(data);
      setIsLoading(false);
    };

    if (groupName) {
      groupNameVar(groupName);
      fetchData(clientId, groupName);
    }
  }, [clientId, groupName]);

  if (!urlParams.clientId) {
    console.error("Missing required params");
    return null;
  }

  if (isLoading) {
    return <GlobalLoader />;
  }

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path={STEP_URLS.intro} element={<IntroWizard />} />
        <Route path="/" element={<NavigateWithState to={STEP_URLS.start} />} />
        <Route path="*" element={<RadarWizard />} />
      </Routes>
    </Router>
  );
}

export default LayoutRouter;
