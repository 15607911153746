import { useReactiveVar } from "@apollo/client";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { lastIntroStepVar, selectedPresetVar } from "../../../apollo/state";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import RadioGroup from "../../../components/RadioGroup";
import SectionTitle from "../../../components/SectionTitle";
import Select from "../../../components/Select";
import Typography from "../../../components/Typography";
import { STEP_URLS } from "../../../constants";
import { INTRO_STEP } from "../../../constants/routing";
import { useNavigateWithState } from "../../../utils/useNavigateWithState";
import { ButtonsContainer, StepWrapper } from "../styled";
import RadioLabel from "./RadioLabel";
import { WeightingProps } from "./types";

function Weighting({ availablePresets, weightByFieldMap }: WeightingProps) {
  const { t } = useTranslation();
  const navigate = useNavigateWithState();
  const selectedPreset = useReactiveVar(selectedPresetVar);
  const lastIntroStep = useReactiveVar(lastIntroStepVar);
  const standardPresetKey = availablePresets.standard.key;

  function handlePresetSelection(preset: string) {
    selectedPresetVar(preset);
  }

  function handleNextClick() {
    navigate(STEP_URLS.pickFields);
  }

  function handleBackClick() {
    navigate(
      lastIntroStep
        ? STEP_URLS.intro.replace(INTRO_STEP, lastIntroStep)
        : STEP_URLS.start
    );
  }

  const preset = weightByFieldMap["1"][selectedPreset];

  return (
    <StepWrapper>
      <SectionTitle>
        {t("wizard.weighting.title", "weighting of the fields of action")}
      </SectionTitle>
      <Box
        m="0 0 40px"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          "@media (max-width: 1365px)": {
            flexWrap: "wrap",
          },
        }}
      >
        <RadioGroup
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handlePresetSelection(e.target.value);
          }}
        >
          <RadioLabel
            name="selectedPreset"
            value={standardPresetKey}
            defaultChecked={selectedPreset === standardPresetKey}
            title={availablePresets.standard.name}
            subtitle={t("wizard.weighting.weightedEqually", "weighted equally")}
          />
          <Typography>{t("wizard.weighting.or", "or")}</Typography>
          <RadioLabel
            name="selectedPreset"
            value={availablePresets.custom[0].key}
            defaultChecked={selectedPreset !== standardPresetKey}
            title={t("wizard.weighting.sample", "Sample")}
            subtitle={t("wizard.weighting.choose", "choose")}
          />
        </RadioGroup>
        <Select
          value={selectedPreset}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            handlePresetSelection(e.currentTarget.value)
          }
        >
          {selectedPreset !== standardPresetKey &&
            availablePresets.custom.map(p => (
              <option key={p.key} value={p.key}>
                {p.name}
              </option>
            ))}
        </Select>
      </Box>
      <Box
        sx={{
          display: "grid",
          alignItems: "top",
          rowGap: "15px",
          overflow: "auto",
          height: "100%",
        }}
      >
        <Box dangerouslySetInnerHTML={{ __html: preset.description }}></Box>
      </Box>
      <ButtonsContainer>
        <Button onClick={handleBackClick}>
          {t("wizard.common.back", "Back")}
        </Button>
        <Button onClick={handleNextClick}>
          {t("wizard.common.next", "Next")}
        </Button>
      </ButtonsContainer>
    </StepWrapper>
  );
}

export default Weighting;
