import { gql } from "@apollo/client";

export const GET_FIELDS_DATA = gql`
  query getFieldsData($sheetId: String, $localeId: String) {
    fields(sheetId: $sheetId, localeId: $localeId) {
      id: number
      name
      typeId: type
      quadrant
      position
      description
      locked
      testId
    }

    fieldTypes(sheetId: $sheetId, localeId: $localeId) {
      id: number
      name
      baseColor
    }

    weights(sheetId: $sheetId, localeId: $localeId) {
      field
      presets {
        id
        key
        name
        description
        value
        isDefault
      }
    }

    shortMatrix(sheetId: $sheetId, localeId: $localeId) {
      values
    }

    longMatrix(sheetId: $sheetId, localeId: $localeId) {
      values
    }

    matrixLabels(sheetId: $sheetId, localeId: $localeId) {
      values
    }
  }
`;
