import { useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import {
  DtoField,
  HydratedField,
  WeightByFieldMap,
  MatrixObject,
  FieldsConnectionMap,
  FieldTypesMap,
} from "../types";
import { groupExaminationsVar, selectedPresetVar } from "../../../apollo/state";
import {
  getFieldsMap,
  getGroupExaminationsGroup,
  getStaticConnections,
  sortGroupExaminations,
} from "../utils";
import { EMPTY_MATRIX } from "./constants";
import { GroupExamination } from "../../../@types";

export function useHydratedFields(
  fields: DtoField[],
  fieldTypesMap: FieldTypesMap,
  weightByFieldMap: WeightByFieldMap,
  matrix: MatrixObject<number>,
  connectionLabels: MatrixObject<string>
) {
  const selectedPreset = useReactiveVar(selectedPresetVar);
  const groupExaminations = useReactiveVar(groupExaminationsVar);

  const groupExaminationsGroup = useMemo(() => {
    return getGroupExaminationsGroup<GroupExamination>(groupExaminations);
  }, [groupExaminations]);

  const fieldsMap = useMemo(() => {
    return getFieldsMap<DtoField>(fields);
  }, [fields]);

  const staticFieldsConnectionsMap = useMemo<FieldsConnectionMap>(() => {
    if (matrix !== EMPTY_MATRIX && connectionLabels !== EMPTY_MATRIX) {
      return getStaticConnections(matrix, connectionLabels);
    }

    return EMPTY_MATRIX;
  }, [matrix, connectionLabels]);

  const hydratedFields = useMemo(() => {
    if (!Object.keys(weightByFieldMap).length) {
      return [];
    }

    return fields.map<HydratedField>(field => {
      const connectedFields = staticFieldsConnectionsMap[field.id].map(cn => {
        const dtoField = fieldsMap[cn.field];
        return {
          ...dtoField,
          type: fieldTypesMap[dtoField.typeId],
          weight: weightByFieldMap[cn.field][selectedPreset].value,
          connectionValue: cn.value,
          connectionLabel: cn.label,
          size: cn.size,
        };
      });

      const isSelected = !!groupExaminationsGroup[field.testId]?.length;

      return {
        ...field,
        type: fieldTypesMap[field.typeId],
        weight: weightByFieldMap[field.id][selectedPreset].value,
        isSelected,
        matrix,
        connectedFields,
        groupExaminations: sortGroupExaminations(
          groupExaminationsGroup[field.testId]
        ),
      };
    });
  }, [
    fields,
    fieldsMap,
    fieldTypesMap,
    weightByFieldMap,
    selectedPreset,
    matrix,
    staticFieldsConnectionsMap,
    groupExaminationsGroup,
  ]);

  const hydratedFieldsMap = useMemo(() => {
    return getFieldsMap<HydratedField>(hydratedFields);
  }, [hydratedFields]);

  return {
    hydratedFields,
    hydratedFieldsMap,
  };
}
