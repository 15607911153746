import {
  Accordion,
  AccordionDetail,
  AccordionSummary,
} from "../../../../../components/Accordion";
import Typography from "../../../../../components/Typography";
import dayjs from "dayjs";
import { GroupExamination } from "../../../../../@types";
import { Link } from "./styled";
import Box from "../../../../../components/Box";
import { useTranslation } from "react-i18next";

type GroupExaminationsProps = {
  examinations: GroupExamination[];
  isPristine: boolean;
};

export function GroupExaminations({
  examinations,
  isPristine,
}: GroupExaminationsProps) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "10px",
      }}
    >
      {examinations.map((item, index) => (
        <Accordion
          key={`examination-${Math.random().toString()}`} /*hack to collapse previous components*/
          m="0 0 10px"
          defaultOpen={isPristine ? false : index === 0}
        >
          <AccordionSummary>
            <Typography size="16px">
              {t("wizard.pickKeysForField.analysisStarted", "Analysis started")}{" "}
              {dayjs(item.createdAt).format("DD.MM.YYYY HH:mm")}
            </Typography>
          </AccordionSummary>
          <AccordionDetail m="10px 0 20px 20px">
            <Typography>
              {t("wizard.pickKeysForField.resultBoard", "Result Board")}:
            </Typography>
            <Link href={item.boardUrl} target="_blank">
              {item.boardUrl}
            </Link>
            <Typography p="15px 0 0 0">
              {t(
                "wizard.pickKeysForField.participationLink",
                "Participation Link"
              )}
              :
            </Typography>
            <Link href={item.surveyUrl} target="_blank">
              {item.surveyUrl}
            </Link>
          </AccordionDetail>
        </Accordion>
      ))}
    </Box>
  );
}
