import { useCallback, useRef, useState } from "react";
import Background from "./components/Background";
import HorizonSelector from "./components/HorizonSelector";
import Tags from "./components/Tags";
import CurrentTag from "./components/Tag/CurrentTag";
import {
  LEADER_LINES_CONTAINER_ID,
  useLeaderLines,
} from "./hooks/useLeaderLines";
import { TagRef } from "./components/Tags/types";
import { RadarLogo, SvgContainer, Wrapper } from "./styled";
import { RadarProps } from "./types";
import { createTags, createTag } from "./utils";
import { useConfigurableStyles } from "../../context/ConfigurableStyles";
import { navigateToNewSheet } from "../../utils";

function Radar({
  fields,
  fieldsMap,
  isTooltipEnabled,
  isTagClickEnabled,
  onTagClicked,
  currentField,
}: RadarProps) {
  const [tagsRefs, setTagsRefs] = useState<TagRef[]>([]);
  const [refsCreated, setRefsCreated] = useState<boolean>(false);
  const currentFieldRef = useRef<HTMLOrSVGElement>(null);
  const stylesConfig = useConfigurableStyles();

  useLeaderLines(fields, fieldsMap, tagsRefs, currentFieldRef, currentField);

  const tags = createTags(fields, undefined, currentField);

  const handleRefsCreated = useCallback(
    (refs: TagRef[]) => {
      if (refs.length && !refsCreated) {
        setTagsRefs(refs);
        setRefsCreated(true);
      }
    },
    [setTagsRefs, refsCreated]
  );

  function handleModeChange(sheetId: string) {
    navigateToNewSheet(sheetId);
  }

  return (
    <Wrapper>
      <Background />
      {stylesConfig.radarLogoUrl && (
        <RadarLogo src={stylesConfig.radarLogoUrl} />
      )}
      <div id={LEADER_LINES_CONTAINER_ID} />
      <SvgContainer viewBox="0 0 1150 1080">
        <Tags
          isTooltipEnabled={isTooltipEnabled}
          isTagClickEnabled={isTagClickEnabled}
          tags={tags}
          onRefsCreated={handleRefsCreated}
          onTagClicked={onTagClicked}
        />
        {currentField && (
          <CurrentTag
            ref={currentFieldRef}
            {...createTag(currentField, { x: 50, y: 50 })}
          />
        )}
        <HorizonSelector onModeChanged={handleModeChange} />
      </SvgContainer>
    </Wrapper>
  );
}

export default Radar;
