import qs from "qs";

const API_ROOT_URL = process.env.REACT_APP_TEST_GEN_API_URL;

export async function fetchResource(url = "", data = {}) {
  const paramsStr = qs.stringify(data);
  const response = await fetch(`${API_ROOT_URL}/${url}?${paramsStr}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.json();
}

export async function postResource(url = "", data = {}) {
  const response = await fetch(`${API_ROOT_URL}/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json();
}
