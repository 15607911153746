import { HydratedField } from "../types";
import { SelectionMap } from "../../../@types";
import { GroupedFields } from "./types";
import { orderBy } from "lodash-es";

export function getPickFields(
  fields: HydratedField[],
  selectedFields: SelectionMap
) {
  const pickFields = orderBy(
    fields,
    ["weight", "id"],
    ["desc", "asc"]
  ).reduce<GroupedFields>((acc, item) => {
    if (selectedFields[item.id]) {
      return acc;
    }

    acc[item.type.id] = acc[item.type.id] || [];
    acc[item.type.id].push(item);

    return acc;
  }, {});

  return pickFields;
}
