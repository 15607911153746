import { TagRef } from "../components/Tags/types";
import {
  HydratedConnectedField,
  HydratedField,
} from "../../../routes/RadarWizard/types";
import { getFieldsMap } from "../../../routes/RadarWizard/utils";

export function getConnectedNodes(
  renderedNodes: TagRef[],
  currentField: HydratedField
) {
  const fieldsMap = getFieldsMap<HydratedConnectedField>(
    currentField.connectedFields
  );

  const connectedNodes = renderedNodes
    .filter(tagRef => !!fieldsMap[tagRef.props.field.id])
    .map(tagRef => {
      return {
        node: tagRef.node,
        field: { ...tagRef.props.field, ...fieldsMap[tagRef.props.field.id] },
      };
    })
    .sort((a, b) => b.field.size - a.field.size);

  return connectedNodes;
}

export function getLineSize(field: HydratedField) {
  const sizeMultiplier = getLineSizeMultiplier();
  return field.connectedFields.reduce<any>((acc, val, index) => {
    // This logic can be extended later, so we use separate method
    acc[val.id] = val.size * sizeMultiplier;
    return acc;
  }, {});
}

export function getLineSizeMultiplier() {
  // this is used from PDF/Image export, we use big screen resolution to make raster image to look good on the PDF
  // Can be extended later to work with viewport size
  return window.cspLineSizeMultiplier || 1;
}

export function getGravityMultiplier() {
  // same as getLineSizeMultiplier
  return window.cspGravityMultiplier || 1;
}
