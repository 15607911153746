import { CustomizationVariables } from "../@types";
import qs from "qs";
import { parsePosition } from "../components/Radar/positioning-utils";
import { STEP_URLS } from "../constants";

export function ceilValueByScale(value: number, scale: number) {
  return Math.ceil(value / scale) * scale;
}

export function getFieldOpacity(weightValue: number) {
  return ceilValueByScale(weightValue, 0.2);
}

export const isTopQuadrant = (quadrant: number) =>
  quadrant === 1 || quadrant === 2;

export const isLeftQuadrant = (quadrant: number, position: string | null) => {
  if (quadrant === 0) {
    return parsePosition(position!).x < 40;
  }

  return quadrant === 1 || quadrant === 3;
};

export function getCustomizationParams() {
  const {
    sheetId = undefined,
    localeId = undefined,
    clientId = undefined,
    groupName = undefined,
  } = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  }) as CustomizationVariables;

  const retValue: any = {};

  if (sheetId) {
    retValue.sheetId = sheetId;
  }

  if (localeId) {
    retValue.localeId = localeId;
  }

  if (clientId) {
    retValue.clientId = clientId;
  }

  if (groupName) {
    retValue.groupName = groupName;
  }

  return retValue;
}

export function navigateToNewSheet(sheetId: string) {
  const currentParamsObj = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  const newParamsStr = qs.stringify({ ...currentParamsObj, sheetId });

  const hasFieldSelected = document.location.pathname.includes(
    STEP_URLS.pickKeysForField.replace(":fieldId", "")
  );

  if (!hasFieldSelected) {
    document.location.assign(`${document.location.pathname}?${newParamsStr}`);
  } else {
    document.location.assign(`${STEP_URLS.pickFields}?${newParamsStr}`);
  }
}
