import { HorizonSelectorProps } from "./types";
import {
  Container,
  SvgContainer,
  SwitchButton,
  SwitchButtonTet,
} from "./styled";
import { useSettings } from "../../../../context/Settings";
import { getCustomizationParams } from "../../../../utils";

const { sheetId: currentSheetId } = getCustomizationParams();

function HorizonSelector({ onModeChanged }: HorizonSelectorProps) {
  const {
    leftLinkedRadar,
    rightLinkedRadar,
    leftLinkedRadarName,
    rightLinkedRadarName,
  } = useSettings();

  if (!(currentSheetId && leftLinkedRadarName && rightLinkedRadarName)) {
    return null;
  }

  return (
    <SvgContainer x="364" y="990">
      <foreignObject x="0" y="0" width="400" height="45">
        <Container>
          <SwitchButton
            active={currentSheetId === leftLinkedRadar}
            onClick={() => onModeChanged(leftLinkedRadar!)}
          >
            <SwitchButtonTet>{leftLinkedRadarName}</SwitchButtonTet>
          </SwitchButton>
          <SwitchButton
            active={currentSheetId === rightLinkedRadar}
            onClick={() => onModeChanged(rightLinkedRadar!)}
          >
            <SwitchButtonTet>{rightLinkedRadarName}</SwitchButtonTet>
          </SwitchButton>
        </Container>
      </foreignObject>
    </SvgContainer>
  );
}
export default HorizonSelector;
