import { makeVar } from "@apollo/client";
import { SelectionMap, HorizonMode, GroupExamination } from "../@types";
import { HORIZON_MODE } from "../constants";

export type AppState = {
  clientId?: string;
  sheetId?: string;
  localeId?: string;
  selectedPreset: string;
  selectedFields: SelectionMap;
  selectedHorizon: HorizonMode;
};

export const selectedPresetVar = makeVar<string>("");
export const groupNameVar = makeVar<string>("");
export const groupExaminationsVar = makeVar<GroupExamination[]>([]);
export const selectedHorizonVar = makeVar<HorizonMode>(
  HORIZON_MODE.short as HorizonMode
);
export const lastIntroStepVar = makeVar<string>("");
