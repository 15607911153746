import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Tag from "../../../components/Tag";
import Typography from "../../../components/Typography";
import { STEP_URLS } from "../../../constants/routing";
import { ButtonsContainer, StepWrapper } from "../styled";
import { HydratedField } from "../types";
import { useNavigateField } from "../utils";
import { useNavigateWithState } from "../../../utils/useNavigateWithState";
import { useSettings } from "../../../context/Settings";
import { LockedInfo } from "../../../components/LockedInfo";
import { GroupExaminations } from "./components/GroupExaminations";
import {
  createGroupExaminations,
  fetchGroupExaminations,
} from "../../../utils/group-examinations";
import { useReactiveVar } from "@apollo/client";
import { groupNameVar, groupExaminationsVar } from "../../../apollo/state";
import { getCustomizationParams } from "../../../utils";
import { GlobalLoader } from "../../../components/Loader";

type Props = {
  currentField?: HydratedField;
  hydratedFields: HydratedField[];
};

const urlParams = getCustomizationParams();

function PickKeysForField(props: Props) {
  const { currentField } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isPristine, setIsPristine] = useState(true);
  const navigate = useNavigateWithState();
  const { lockedFieldText } = useSettings();
  const navigateField = useNavigateField();
  const groupName = useReactiveVar(groupNameVar);

  if (!currentField) {
    return null;
  }

  async function startNewAnalysis() {
    setIsLoading(true);
    const crateResponse = await createGroupExaminations(
      urlParams.clientId,
      currentField!.testId,
      groupName
    );

    if (crateResponse.error) {
      alert(crateResponse.error);
    }

    groupExaminationsVar(
      await fetchGroupExaminations(urlParams.clientId, groupName)
    );
    setIsLoading(false);
    setIsPristine(false);
  }

  return (
    <StepWrapper>
      {isLoading && <GlobalLoader />}
      <Box sx={{ height: "100%", overflow: "auto" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
          m="0 0 20px"
        >
          <Typography>{t("wizard.pickKeysForField.field", "Field")}</Typography>
          <Tag
            type={currentField.type}
            name={currentField.name}
            quadrant={currentField.quadrant}
            weight={currentField.weight}
          />
        </Box>
        <Typography lh="20px" m="0 0 20px">
          <Box
            as="span"
            sx={{
              margin: "-2px 0 0 -20px",
              fontSize: "24px",
              position: "absolute",
            }}
          >
            &#8505;
          </Box>
          {currentField.description}
        </Typography>
        <Box m="0 0 20px">
          {!!currentField.connectedFields.length && (
            <Typography>
              {t("wizard.pickKeysForField.connectedWith", "Connected with")}
            </Typography>
          )}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "25px 15px",
              marginTop: "20px",
            }}
          >
            {currentField.connectedFields.map(f => (
              <Fragment key={f.id}>
                <Tag
                  type={f.type}
                  name={f.name}
                  quadrant={f.quadrant}
                  weight={f.weight}
                  onClick={() => {
                    navigateField(f.id);
                  }}
                />
                <Typography
                  size="14px"
                  dangerouslySetInnerHTML={{
                    __html: f.connectionLabel,
                  }}
                />
              </Fragment>
            ))}
          </Box>
        </Box>
        {!!currentField.groupExaminations.length && (
          <>
            <Typography>
              {t("wizard.pickFields.pastAnalysis", "Past analysis:")}
            </Typography>
            <GroupExaminations
              examinations={currentField.groupExaminations}
              isPristine={isPristine}
            />
          </>
        )}
      </Box>
      <ButtonsContainer>
        <Button
          onClick={() => {
            navigate(STEP_URLS.pickFields);
          }}
        >
          {t("wizard.pickKeysForField.back", "Back")}
        </Button>
        <Button onClick={startNewAnalysis} disabled={currentField.locked}>
          {t("wizard.pickKeysForField.startAnalysis", "Start new analysis")}
        </Button>
      </ButtonsContainer>
      {currentField.locked && <LockedInfo>{lockedFieldText}</LockedInfo>}
    </StepWrapper>
  );
}

export default PickKeysForField;
