import {
  Weight,
  WeightByFieldMap,
  DtoMatrix,
  HydratedField,
  FieldsConnectionMap,
} from "./types";
import { keyBy, orderBy, groupBy } from "lodash-es";
import dayjs from "dayjs";
import { GenericObject, GroupExamination } from "../../@types";
import { matchPath, useLocation } from "react-router-dom";
import { STEP_URLS } from "../../constants";
import { FIELD_ID_PARAM } from "../../constants/routing";
import { useNavigateWithState } from "../../utils/useNavigateWithState";

export const TOP_FIELDS_COUNT = 5;

export function getAvailablePresets(weights: Weight[]) {
  const [standard, ...custom] = weights[0].presets;
  return {
    standard,
    custom,
  };
}

export function getWeightByFieldMap(weights: Weight[]) {
  return weights.reduce<WeightByFieldMap>((acc, weight) => {
    acc[weight.field] = keyBy(weight.presets, "key");
    return acc;
  }, {});
}

export function getFieldsMap<T>(fields: T[]) {
  return keyBy(fields, "id");
}

export function getGroupExaminationsGroup<T>(data: T[]) {
  return groupBy(data, "testId");
}

export function sortGroupExaminations(data: GroupExamination[]) {
  return orderBy(
    data,
    [
      item => {
        return dayjs(item.createdAt).unix();
      },
    ],
    ["desc"]
  );
}

export function processMatrix<TValueType>(matrix: DtoMatrix<TValueType>) {
  function getIndex(i: number) {
    return (i + 1).toString();
  }

  return matrix.values.reduce<GenericObject>((acc, item, i) => {
    acc[getIndex(i)] = item.reduce<GenericObject>((subAcc, subItem, j) => {
      subAcc[getIndex(j)] = subItem;
      return subAcc;
    }, {});
    return acc;
  }, {});
}

export function someUrlsMatches(
  urls: string[],
  pathName: string,
  isNot: boolean
) {
  if (isNot) {
    return urls.every(pattern => {
      return !matchPath(pattern, pathName);
    });
  } else {
    return urls.some(pattern => {
      return !!matchPath(pattern, pathName);
    });
  }
}

export function useUrlMatching(urls: string[], isNot: boolean = false) {
  const location = useLocation();
  return someUrlsMatches(urls, location.pathname, isNot);
}

export function useTagClickEnabled() {
  return useUrlMatching([STEP_URLS.pickFields, STEP_URLS.pickKeysForField]);
}

export function useRadarTooltipEnabled() {
  return useUrlMatching([STEP_URLS.start, STEP_URLS.intro], true);
}

export function useNavigateField() {
  const navigate = useNavigateWithState();

  return (fieldId: string) => {
    navigate(STEP_URLS.pickKeysForField.replace(FIELD_ID_PARAM, fieldId));
  };
}

export function useCurrentField(
  hydratedFieldsMap: Record<string, HydratedField>
) {
  const location = useLocation();
  const match = matchPath(STEP_URLS.pickKeysForField, location.pathname);

  if (match) {
    return hydratedFieldsMap[match.params.fieldId as string];
  }
}

export function getStaticConnections(
  matrix: GenericObject,
  connectionLabels: GenericObject
) {
  const keys = Object.keys(matrix);
  return keys.reduce<FieldsConnectionMap>((acc, key) => {
    const values = orderBy(
      Object.keys(matrix[key])
        .filter(innerKey => innerKey !== key)
        .map(innerKey => ({
          field: innerKey,
          value: matrix[key][innerKey],
          label: connectionLabels[key][innerKey],
        }))
        .filter(({ value }) => value > 0),
      ["value"],
      ["desc"]
    );
    acc[key] = values.map(item => ({
      ...item,
      size: item.value,
    }));
    return acc;
  }, {});
}
