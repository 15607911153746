import { useState } from "react";
import Modal from "react-modal";
import { useReactiveVar } from "@apollo/client";
import { STEP_URLS } from "../../../constants";
import { StepWrapper } from "../styled";
import { INTRO_STEP } from "../../../constants/routing";
import { useNavigateWithState } from "../../../utils/useNavigateWithState";
import TextInfo from "./text-info";
import { GlobalLoader } from "../../../components/Loader";
import { KeyAlreadyExist, KeyNotFoundExist } from "./modal-content";
import { groupExaminationsVar, groupNameVar } from "../../../apollo/state";
import { getCustomizationParams } from "../../../utils";
import { fetchGroupExaminations } from "../../../utils/group-examinations";
import { GroupExamination } from "../../../@types";

export enum ModalState {
  off,
  keyExist,
  keyNotFound,
}

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "350px",
  },
};

const params = getCustomizationParams();

function Welcome() {
  const [isLoading, setIsLoading] = useState(false);
  const [groupExaminations, setGroupExaminations] = useState<
    GroupExamination[]
  >([]);
  const [modalState, setModalState] = useState(ModalState.off);
  const groupName = useReactiveVar(groupNameVar);
  const navigate = useNavigateWithState();

  async function fetchGroupExaminationsWithLoadingState() {
    setIsLoading(true);
    const data = await fetchGroupExaminations(params.clientId, groupName);
    setGroupExaminations(data);
    setIsLoading(false);
    return data;
  }

  function navigateAndSetStateVar(
    navigateTo: string,
    items: GroupExamination[] = groupExaminations
  ) {
    groupExaminationsVar(items);
    navigate(`${navigateTo}?groupName=${groupName}`);
  }

  async function handleStartOrIntro(navigateCB: () => void) {
    const groupExaminations = await fetchGroupExaminationsWithLoadingState();
    if (groupExaminations.length) {
      setModalState(ModalState.keyExist);
    } else {
      navigateCB();
    }
  }

  async function handleStartClick() {
    await handleStartOrIntro(() => {
      navigateAndSetStateVar(STEP_URLS.weighting);
    });
  }

  async function handleIntroClick() {
    await handleStartOrIntro(() => {
      navigateAndSetStateVar(STEP_URLS.intro.replace(INTRO_STEP, "1"));
    });
  }

  async function handleLoadClick() {
    const groupExaminations = await fetchGroupExaminationsWithLoadingState();
    if (!groupExaminations.length) {
      setModalState(ModalState.keyNotFound);
    } else {
      navigateAndSetStateVar(STEP_URLS.weighting, groupExaminations);
    }
  }

  function hideModal() {
    setModalState(ModalState.off);
  }

  function loadExistingProject() {
    navigateAndSetStateVar(STEP_URLS.weighting);
  }

  return (
    <StepWrapper sx={{ width: "70%" }}>
      <TextInfo
        onIntroClicked={handleIntroClick}
        onLoadClicked={handleLoadClick}
        onStartClicked={handleStartClick}
      />
      {isLoading && <GlobalLoader />}
      <Modal isOpen={modalState !== ModalState.off} style={modalStyles}>
        {modalState === ModalState.keyExist && (
          <KeyAlreadyExist
            onLoadClicked={loadExistingProject}
            onCancelClicked={hideModal}
          />
        )}
        {modalState === ModalState.keyNotFound && (
          <KeyNotFoundExist onCancelClicked={hideModal} />
        )}
      </Modal>
    </StepWrapper>
  );
}

export default Welcome;
