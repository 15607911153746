import { useTranslation } from "react-i18next";
import Typography from "../../../components/Typography";
import Box from "../../../components/Box";
import Button from "../../../components/Button";

export type KeyAlreadyExistProps = {
  onLoadClicked: () => void;
  onCancelClicked: () => void;
};

export type KeyNotFoundProps = {
  onCancelClicked: () => void;
};

export function KeyAlreadyExist({
  onLoadClicked,
  onCancelClicked,
}: KeyAlreadyExistProps) {
  const { t } = useTranslation();

  return (
    <>
      <Typography size={"18px"} sx={{ textAlign: "center" }}>
        {t(
          "wizard.welcome.keyInUseTitle",
          "Key is already in use. Load project?"
        )}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          paddingTop: "20px;",
        }}
      >
        <Button onClick={onLoadClicked}>
          {t("wizard.welcome.keyInUseLoad", "Load")}
        </Button>
        <Button onClick={onCancelClicked}>
          {t("wizard.welcome.keyInUseCancel", "Cancel")}
        </Button>
      </Box>
    </>
  );
}

export function KeyNotFoundExist({ onCancelClicked }: KeyNotFoundProps) {
  const { t } = useTranslation();

  return (
    <>
      <Typography size={"18px"} sx={{ textAlign: "center" }}>
        {t("wizard.welcome.keyNotExistTitle", "Key does not exist")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          paddingTop: "20px;",
        }}
      >
        <Button onClick={onCancelClicked}>
          {t("wizard.welcome.keyNotExistOK", "OK")}
        </Button>
      </Box>
    </>
  );
}
