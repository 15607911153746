import styled from "styled-components";

export const UnderlinedButton = styled.u<{ disabled: boolean }>`
  cursor: pointer;
  ${props =>
    props.disabled
      ? `
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
  `
      : ""};
`;

export const StyledInput = styled.input`
  height: 35px;
  width: 350px;
  padding: 0 5px;
`;

export const StyledLink = styled.button`
  display: inline-block;
  padding-top: 5px;
  cursor: pointer;
  color: var(--link-color);
  text-decoration: underline;
  border: 0;
  background: none;
`;

export const StyledDescription = styled.div`
  padding-bottom: 20px;
`;
