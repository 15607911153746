import { useTranslation, Trans } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useReactiveVar } from "@apollo/client";
import { groupExaminationsVar, groupNameVar } from "../../../apollo/state";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Typography from "../../../components/Typography";
import { useConfigurableStyles } from "../../../context/ConfigurableStyles";
import { useSettings } from "../../../context/Settings";
import {
  StyledDescription,
  StyledInput,
  StyledLink,
  UnderlinedButton,
} from "./styled";

export type Props = {
  onIntroClicked: () => void;
  onLoadClicked: () => void;
  onStartClicked: () => void;
};

function TextInfo({ onStartClicked, onLoadClicked, onIntroClicked }: Props) {
  const projectId = useReactiveVar(groupNameVar);
  const { t } = useTranslation();
  const styles = useConfigurableStyles();
  const { demoMode } = useSettings();

  function updateProjectId(rawValue: string) {
    const value = rawValue.trim();
    groupNameVar(value);
    groupExaminationsVar([]);
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        gap: "15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {styles.welcomeLogoUrl && (
          <Box
            as="img"
            src={styles.welcomeLogoUrl}
            alt="Logo"
            sx={{
              minWidth: "200px",
              maxWidth: "320px",
              position: "absolute",
              right: " 50px",
              top: "10px",
            }}
          />
        )}
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Typography
            size="38px"
            uppercase
            ls="1.2px"
            color="headline-color"
            font="headline-text-font-type"
          >
            {t("wizard.welcome.title", "Welcome to")}
          </Typography>
          <Typography
            size="70px"
            bold
            uppercase
            ls="2.4px"
            color="headline-color"
            font="headline-text-font-type"
            sx={{ marginBottom: "55px" }}
          >
            {t("wizard.welcome.subTitle", "HR Analytics")}
          </Typography>
          <Typography size="21px" ls="0.75px" m="0 0 25px">
            {t(
              "wizard.welcome.description",
              "Find out in just a few steps, how to plan and design your business more sustainable."
            )}
          </Typography>
          <Box
            sx={{
              display: "flex",
              paddingTop: "100px",
            }}
          >
            <Box>
              <Typography size={"18px"} p="0 0 5px 0">
                {t("wizard.welcome.projectId", "Project-ID")}
              </Typography>
              <StyledInput
                value={projectId}
                onChange={({ currentTarget: { value } }) => {
                  updateProjectId(value);
                }}
              />
              <StyledLink
                onClick={() => {
                  updateProjectId(uuidv4());
                }}
              >
                {t("wizard.welcome.createRandomHash", "Create random Hash")}
              </StyledLink>
            </Box>
            <Box
              sx={{
                paddingLeft: "20px",
              }}
            >
              <Typography>
                <Trans i18nKey="wizard.welcome.projectIdDescription">
                  <StyledDescription>
                    Define a new key to start a new analysis project or enter
                    your existing key.
                  </StyledDescription>
                  <StyledDescription>
                    Using a key-phrase is an easy way to handle all analysis
                    with your team. You don't need an account. Your data is
                    simply accessible by this key.
                  </StyledDescription>
                  <StyledDescription>
                    When creating several analysis under the same key, this tool
                    helps to identify the dependencies of all analysis and
                    provides you with a convenient overview cokpit.
                  </StyledDescription>
                </Trans>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "565px",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "20px",
          }}
        >
          <div>
            <Button onClick={onIntroClicked} disabled={!projectId}>
              START
            </Button>
          </div>
          {!demoMode && (
            <>
              <div>
                <Button onClick={onLoadClicked} disabled={!projectId}>
                  {t("wizard.welcome.load", "LOAD")}
                </Button>
              </div>
              <Typography size="15px">
                <UnderlinedButton
                  onClick={onStartClicked}
                  disabled={!projectId}
                >
                  START
                </UnderlinedButton>{" "}
                {t("wizard.welcome.withoutIntro", "WITHOUT INTRO")}
              </Typography>
              <Typography size="14px" nowrap>
                {t(
                  "wizard.welcome.openFile",
                  "Open the saved configuration file"
                )}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default TextInfo;
