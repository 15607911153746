import { useTranslation } from "react-i18next";
import SectionTitle from "../../../components/SectionTitle";
import { StepWrapper, ButtonsContainer } from "../styled";
import { PickFieldsProps } from "./types";
import { getPickFields } from "./utils";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import { STEP_URLS } from "../../../constants";
import FieldsContainer from "./FieldsContainer";
import { useNavigateWithState } from "../../../utils/useNavigateWithState";
import Typography from "../../../components/Typography";

function PickFields({
  hydratedFields,
  onTagClicked,
  fieldTypes,
}: PickFieldsProps) {
  const { t } = useTranslation();
  const navigate = useNavigateWithState();
  const groupedFields = getPickFields(hydratedFields, {});

  function handleBackClick() {
    navigate(STEP_URLS.weighting);
  }

  return (
    <StepWrapper>
      <SectionTitle>
        {t("wizard.pickFields.title", "select areas of action")}
      </SectionTitle>
      <Box
        m="0 0 40px"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          overflow: "auto",
          margin: "0 -10px",
          "@media (max-width: 1580px)": {
            alignItems: "center",
          },
        }}
      >
        <>
          {fieldTypes.map(fType => (
            <Box key={`groupedFields-${fType.id}`} m="0 0 15px">
              <Typography size="17px" p="0 0 0 11px">
                {fType.name}
              </Typography>
              <FieldsContainer
                fields={groupedFields[fType.id].filter(
                  (f: { isSelected: boolean }) => !f.isSelected
                )}
                onTagClicked={onTagClicked}
              />
            </Box>
          ))}
        </>
        <Box
          p="30px 0 10px 0"
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Typography size="22px" p="0 0 0 11px">
            {t("wizard.pickFields.pastAnalysis", "Past analysis:")}
          </Typography>
          <FieldsContainer
            fields={hydratedFields.filter(f => f.isSelected)}
            onTagClicked={onTagClicked}
          />
        </Box>
      </Box>
      <ButtonsContainer>
        <Button onClick={handleBackClick}>
          {t("wizard.pickFields.back", "BACK")}
        </Button>
      </ButtonsContainer>
    </StepWrapper>
  );
}

export default PickFields;
