import Radio from "../../../../components/Radio";
import Typography from "../../../../components/Typography";

type Props = {
  name: string;
  defaultChecked: boolean;
  value: string;
  title: string;
  subtitle: string;
};

function RadioLabel({ name, defaultChecked, value, title, subtitle }: Props) {
  return (
    <label>
      <Radio defaultChecked={defaultChecked} name={name} value={value} />
      <Typography size="17px" as="span" bold>
        {title}
      </Typography>
      <br />
      <Typography m="0 0 0 21px" size="12px">
        {subtitle}
      </Typography>
    </label>
  );
}

export default RadioLabel;
