export const CLIENT_DATA_CSS_ID = "client-data-css";

export const tryEmbedCssLink = (cssLink: string) => {
  if (cssLink) {
    const dynamicStylesLink = document.createElement("link");
    dynamicStylesLink.rel = "stylesheet";
    dynamicStylesLink.type = "text/css";
    dynamicStylesLink.href = cssLink;
    document.getElementById(CLIENT_DATA_CSS_ID)!.appendChild(dynamicStylesLink);
  }
};
