import { RefObject, useLayoutEffect } from "react";
import LeaderLine from "react-leader-line";
import { TagRef } from "../components/Tags/types";
import { HydratedField } from "../../../routes/RadarWizard/types";
import { getFieldOpacity, isLeftQuadrant } from "../../../utils";
import { LeaderLineTag } from "./types";
import { getConnectedNodes, getLineSize } from "./utils";
import { GenericObject } from "../../../@types";

type LineProps = {
  sizeMap: Record<string, number>;
};

const ANCHOR_POSITION = {
  leftCenter: { x: "2%", y: "50%" }, // +- 2% offset is needed to go behind tag
  rightCenter: { x: "98%", y: "50%" },
};

export const LEADER_LINES_CONTAINER_ID = "leader-lines-container";
const LINE_SIZE_MULTIPLIER = 3;

const clearLeaderLines = () => {
  document.querySelectorAll(".leader-line").forEach(e => e.remove());
};

const moveLeaderLinesToRadar = () => {
  const container = document.getElementById(LEADER_LINES_CONTAINER_ID);
  document.querySelectorAll(".leader-line").forEach(e => {
    container!.appendChild(e);
  });
};

function createLeaderLine(
  fieldA: LeaderLineTag,
  fieldB: LeaderLineTag,
  fieldAAnchor: GenericObject,
  extraProps: GenericObject
) {
  try {
    new LeaderLine(
      LeaderLine.pointAnchor(fieldA.node, fieldAAnchor),
      LeaderLine.pointAnchor(fieldB.node, {
        ...(isLeftQuadrant(fieldB.field.quadrant, fieldB.field.position)
          ? ANCHOR_POSITION.rightCenter
          : ANCHOR_POSITION.leftCenter),
      }),
      extraProps
    );

    // TODO for tooltip feature
    // const linePath = document.querySelector('body>.leader-line:last-of-type path') as HTMLElement;
    // linePath.dataset.tip = "ded";
    // remove disable pointer event from global styles
    // add new line layer to the body
  } catch (e) {}
}

function createLeaderLineForNode(
  fieldA: LeaderLineTag,
  fieldB: LeaderLineTag,
  fieldAAnchor: GenericObject,
  lineProps: LineProps,
  gravityProps: GenericObject
) {
  const fieldAOpacity = getFieldOpacity(fieldA.field.weight);
  const fieldBOpacity = getFieldOpacity(fieldB.field.weight);

  const styleProps = {
    endPlug: "behind",
    size: lineProps.sizeMap[fieldB.field.id] * LINE_SIZE_MULTIPLIER,
    gradient: {
      startColor: `rgba(${fieldA.field.type.baseColor}, ${fieldAOpacity})`,
      endColor: `rgba(${fieldB.field.type.baseColor}, ${fieldBOpacity})`,
    },
  };

  const extraProps = { ...gravityProps, ...styleProps };

  // Double the lines to avoid showing BG on the opacity
  createLeaderLine(fieldA, fieldB, fieldAAnchor, {
    ...extraProps,
    gradient: {
      startColor: "var(--white)",
      endColor: "var(--white)",
    },
  });
  createLeaderLine(fieldA, fieldB, fieldAAnchor, extraProps);
}

function createLeaderLineForCurrentNode(
  fieldA: LeaderLineTag,
  fieldB: LeaderLineTag,
  lineExtraProps: LineProps
) {
  const gravityProps = {
    startSocketGravity: isLeftQuadrant(
      fieldB.field.quadrant,
      fieldB.field.position
    )
      ? [-50, 0]
      : [50, 0],
    endSocketGravity: isLeftQuadrant(
      fieldB.field.quadrant,
      fieldB.field.position
    )
      ? [50, 0]
      : [-50, 0],
  };

  const fieldAnchor = isLeftQuadrant(
    fieldB.field.quadrant,
    fieldB.field.position
  )
    ? ANCHOR_POSITION.leftCenter
    : ANCHOR_POSITION.rightCenter;

  createLeaderLineForNode(
    fieldA,
    fieldB,
    fieldAnchor,
    lineExtraProps,
    gravityProps
  );
}

export function useLeaderLines(
  fields: HydratedField[],
  fieldsMap: Record<string, HydratedField>,
  renderedNodes: TagRef[],
  currentFieldNode: RefObject<HTMLOrSVGElement>,
  currentField?: HydratedField
) {
  useLayoutEffect(() => {
    clearLeaderLines();

    if (renderedNodes.length) {
      if (currentField && currentFieldNode.current) {
        // Show lines for current field only
        const currentLeaderLineTag = {
          node: currentFieldNode.current,
          field: currentField,
        };

        const connectedNodes = getConnectedNodes(renderedNodes, currentField);
        const sizeMap = getLineSize(currentField);
        connectedNodes.forEach(node => {
          createLeaderLineForCurrentNode(currentLeaderLineTag, node, {
            sizeMap,
          });
        });
      }
    }

    moveLeaderLinesToRadar();
  }, [renderedNodes, currentField, currentFieldNode, fields, fieldsMap]);
}
