import { fetchResource, postResource } from "./request";
import { GroupExamination } from "../@types";

export async function fetchGroupExaminations(clientId: string, name: string) {
  return (await fetchResource("public/analytics-radar/group-examinations", {
    clientId,
    name,
  })) as GroupExamination[];
}

export async function createGroupExaminations(
  clientId: string,
  testId: string,
  name: string
) {
  return await postResource("public/analytics-radar/group-examinations", {
    clientId,
    testId,
    name,
  });
}
