import styled from "styled-components";
import { SwitchButtonProps } from "./types";

export const Container = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  min-width: 300px;
  display: flex;
  justify-content: space-around;
  border: 1px solid #7f7f7f;
  border-radius: 20px;
`;

export const SwitchButton = styled.div<SwitchButtonProps>`
  position: absolute;
  top: -1px;
  height: calc(100% + 2px);
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: rgba(0,0,0,0.5);
  font-family: Roboto-Regular, Roboto;
  &:first-child {
    left: 0;
  }
  &:last-child {
    left: 50%;
  }

  ${props =>
    props.active
      ? `
    background-color: #ccc;
    width: calc(52% + 5px);
    border: 3px solid #7f7f7f;
    font-family: Roboto-Bold, Roboto;
    font-weight: 700;
    &:last-child {
      left: calc(48% - 5px);
    }
  `
      : `
    width: calc(48% + 5px);
  `}
}
`;

export const SwitchButtonTet = styled.div`
  font-size: 20px;
`;

export const SvgContainer = styled.svg``;
